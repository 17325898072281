@import '../../app/common/config';

/*
* Theme
*/

body,
html {
    color: #000000;
    font-family: "Roboto", sans-serif;
    height: 100%;
    letter-spacing: 0.025rem;
}

a,
a:active,
a:hover,
a:focus {
    text-decoration: none;
}

a:focus {
    outline: none !important;
}

a[href^=tel] {
    color: inherit;
}


/*
* Background color
*/

.bg-black {
    background-color: #000000 !important;
}

.bg-black-light {
    background-color: #20222b !important;
}

.bg-gray {
    background-color: #fafafa !important;
}

.bg-gray-light {
    // background-color: #fcfcfc !important;
    background-color: #f9f9f9 !important;
}

.bg-gray-dark {
    background-color: #666666 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-blue {
    background-color: #016cff !important;
}

.bg-blue-2 {
    background-color: #009cdb !important;
}

.bg-green {
    background-color: #0baf4e !important;
}

.bg-orange {
    background-color: #f9461d !important;
}

.bg-orange-2 {
    background-color: #f26702 !important;
}

.bg-pink {
    background-color: #ff1a56 !important;
}

.bg-purple {
    background-color: #aa2e85 !important;
}

.bg-red {
    background-color: #ef4135 !important;
}


/*
* Background image
*/

.bg-cover {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
}

.bg-parallax {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 150%;
    overflow: hidden;
    position: relative;
    width: 100%;
    top: -75%;
}


/*
* Background overlay
*/

.bg-overlay-black-1,
.bg-overlay-black-2,
.bg-overlay-black-3,
.bg-overlay-black-4,
.bg-overlay-black-5,
.bg-overlay-black-6,
.bg-overlay-black-7,
.bg-overlay-black-8,
.bg-overlay-black-9,
.bg-overlay-gray-1,
.bg-overlay-gray-2,
.bg-overlay-gray-3,
.bg-overlay-gray-4,
.bg-overlay-gray-5,
.bg-overlay-gray-6,
.bg-overlay-gray-7,
.bg-overlay-gray-8,
.bg-overlay-gray-9 {
    position: relative;
}

.bg-overlay-black-1:before,
.bg-overlay-black-2:before,
.bg-overlay-black-3:before,
.bg-overlay-black-4:before,
.bg-overlay-black-5:before,
.bg-overlay-black-6:before,
.bg-overlay-black-7:before,
.bg-overlay-black-8:before,
.bg-overlay-black-9:before,
.bg-overlay-gray-1:before,
.bg-overlay-gray-2:before,
.bg-overlay-gray-3:before,
.bg-overlay-gray-4:before,
.bg-overlay-gray-5:before,
.bg-overlay-gray-6:before,
.bg-overlay-gray-7:before,
.bg-overlay-gray-8:before,
.bg-overlay-gray-9:before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.bg-overlay-black-1:before {
    background: rgba(0, 0, 0, 0.1) !important;
}

.bg-overlay-black-2:before {
    background: rgba(0, 0, 0, 0.2) !important;
}

.bg-overlay-black-3:before {
    background: rgba(0, 0, 0, 0.3) !important;
}

.bg-overlay-black-4:before {
    background: rgba(0, 0, 0, 0.4) !important;
}

.bg-overlay-black-5:before {
    background: rgba(0, 0, 0, 0.5) !important;
}

.bg-overlay-black-6:before {
    background: rgba(0, 0, 0, 0.6) !important;
}

.bg-overlay-black-7:before {
    background: rgba(0, 0, 0, 0.7) !important;
}

.bg-overlay-black-8:before {
    background: rgba(0, 0, 0, 0.8) !important;
}

.bg-overlay-black-9:before {
    background: rgba(0, 0, 0, 0.9) !important;
}

.bg-overlay-gray-1:before {
    background: rgba(37, 37, 37, 0.1) !important;
}

.bg-overlay-gray-2:before {
    background: rgba(37, 37, 37, 0.2) !important;
}

.bg-overlay-gray-3:before {
    background: rgba(37, 37, 37, 0.3) !important;
}

.bg-overlay-gray-4:before {
    background: rgba(37, 37, 37, 0.4) !important;
}

.bg-overlay-gray-5:before {
    background: rgba(37, 37, 37, 0.5) !important;
}

.bg-overlay-gray-6:before {
    background: rgba(37, 37, 37, 0.6) !important;
}

.bg-overlay-gray-7:before {
    background: rgba(37, 37, 37, 0.7) !important;
}

.bg-overlay-gray-8:before {
    background: rgba(37, 37, 37, 0.8) !important;
}

.bg-overlay-gray-9:before {
    background: rgba(37, 37, 37, 0.9) !important;
}


/*
* Border
*/

.border {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.border-thick {
    border-width: 2px !important;
}

.border-medium-thick {
    border-width: 3px !important;
}

.border-extra-thick {
    border-width: 4px !important;
}

.border-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
}

.border-y-0 {
    border-bottom: 0 !important;
    border-top: 0 !important;
}


/*
* Border color
*/

.border-black {
    border-color: #000000 !important;
}

.border-black-light {
    border-color: #20222b !important;
}

.border-gray {
    border-color: #fafafa !important;
}

.border-gray-light {
    border-color: #fcfcfc !important;
}

.border-gray-dark {
    border-color: #666666 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.border-blue {
    border-color: #016cff !important;
}

.border-blue-2 {
    border-color: #009cdb !important;
}

.border-green {
    border-color: #0baf4e !important;
}

.border-orange {
    border-color: #f9461d !important;
}

.border-orange-2 {
    border-color: #f26702 !important;
}

.border-pink {
    border-color: #ff1a56 !important;
}

.border-purple {
    border-color: #aa2e85 !important;
}

.border-red {
    border-color: #ef4135 !important;
}


/*
* Box shadow
*/

.box-shadow {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
}

.box-shadow-shallow {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow-wide {
    box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}


/*
* Button
*/

.btn-white,
.btn-hover-white:hover {
    color: #000000 !important;
}

.btn,
.btn:hover,
.btn:focus {
    color: #ffffff;
}

.btn-white.btn-hover-black:hover,
.btn-white.btn-hover-black-light:hover,
.btn-white.btn-hover-gray:hover,
.btn-white.btn-hover-gray-light:hover,
.btn-white.btn-hover-gray-dark:hover,
.btn-white.btn-hover-white:hover,
.btn-white.btn-hover-blue:hover,
.btn-white.btn-hover-blue-2:hover,
.btn-white.btn-hover-green:hover,
.btn-white.btn-hover-orange:hover,
.btn-white.btn-hover-orange-2:hover,
.btn-white.btn-hover-pink:hover,
.btn-white.btn-hover-purple:hover,
.btn-white.btn-hover-red:hover {
    color: #ffffff !important;
}

.btn-shadow,
.btn-shadow:focus {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3) !important;
}

.btn {
    border: none !important;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: $font-NotoSansHK, sans-serif;
    font-weight: 600;
    letter-spacing: 0.093rem;
    margin-right: 1rem;
    outline: 0;
    padding: 0.75rem 1.375rem;
    text-transform: uppercase;
}

.btn:focus {
    box-shadow: none;
}

.btn-circle {
    border-radius: 50% !important;
}

.btn-icon {
    height: 37px;
    line-height: 37px;
    width: 37px;
    padding: 0 !important;
}

.btn-icon i {
    margin: 0 !important;
}

.btn i {
    margin-right: 0.187rem;
}

.btn-shadow:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 3px 8px rgba(0, 0, 0, 0.3) !important;
}


/*
* Button size
*/

.btn-extra-small {
    font-size: 0.625rem;
    padding: 0.625rem 0.75rem 0.5rem;
    margin-right: 0.75rem;
}

.btn-extra-small.btn-icon {
    height: 30px;
    line-height: 31px;
    width: 30px;
}

.btn-small {
    font-size: 0.75rem;
    padding: 0.625rem 0.875rem;
    margin-right: 0.75rem;
}

.btn-small.btn-icon {
    height: 35px;
    line-height: 36px;
    width: 35px;
}

.btn-medium {
    font-size: 0.875rem;
    padding: 0.75rem 1.375rem;
}

.btn-medium.btn-icon {
    height: 41px;
    line-height: 42px;
    width: 41px;
}

.btn-large {
    font-size: 1rem;
    padding: 1.125rem 1.875rem;
}

.btn-large.btn-icon {
    height: 56px;
    line-height: 57px;
    width: 56px;
}

.btn-extra-large {
    font-size: 1.125rem;
    padding: 1.25rem 2.188rem;
}

.btn-extra-large.btn-icon {
    height: 62px;
    line-height: 64px;
    width: 62px;
}


/*
* Button color
*/

.btn-black {
    background-color: #000000 !important;
}

.btn-black-light {
    background-color: #20222b !important;
}

.btn-gray {
    background-color: #fafafa !important;
}

.btn-gray-light {
    background-color: #fcfcfc !important;
}

.btn-gray-dark {
    background-color: #666666 !important;
}

.btn-white {
    background-color: #ffffff !important;
}

.btn-blue {
    background-color: #016cff !important;
}

.btn-blue-2 {
    background-color: #009cdb !important;
}

.btn-green {
    background-color: #0baf4e !important;
}

.btn-orange {
    background-color: #f9461d !important;
}

.btn-orange-2 {
    background-color: #f26702 !important;
}

.btn-pink {
    background-color: #ff1a56 !important;
}

.btn-purple {
    background-color: #aa2e85 !important;
}

.btn-red {
    background-color: #ef4135 !important;
}

.btn-white {
    border: 1px solid rgba(0, 0, 0, 0.7);
}


/*
* Button color - Hover
*/

.btn-hover-black:hover {
    background-color: #000000 !important;
}

.btn-hover-black-light:hover {
    background-color: #20222b !important;
}

.btn-hover-gray:hover {
    background-color: #fafafa !important;
}

.btn-hover-gray-light:hover {
    background-color: #fcfcfc !important;
}

.btn-hover-gray-dark:hover {
    background-color: #666666 !important;
}

.btn-hover-white:hover {
    background-color: #ffffff !important;
}

.btn-hover-blue:hover {
    background-color: #016cff !important;
}

.btn-hover-blue-2:hover {
    background-color: #009cdb !important;
}

.btn-hover-green:hover {
    background-color: #0baf4e !important;
}

.btn-hover-orange:hover {
    background-color: #f9461d !important;
}

.btn-hover-orange-2:hover {
    background-color: #f26702 !important;
}

.btn-hover-pink:hover {
    background-color: #ff1a56 !important;
}

.btn-hover-purple:hover {
    background-color: #aa2e85 !important;
}

.btn-hover-red:hover {
    background-color: #ef4135 !important;
}

.btn-hover-white:hover {
    border: 1px solid rgba(0, 0, 0, 0.7);
}


/*
* Countdown
*/

.clock .clock-item {
    min-width: 80px;
}


/*
* Display
*/

.d-inherit {
    display: inherit !important;
}

.d-table {
    display: table !important;
}

.d-table-cell {
    display: table-cell !important;
}


/*
* Ease
*/

.ease {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}


/*
* Features Block
*/

.features-block {
    text-align: center;
}

.features-block.border-0:before {
    display: none;
}

.features-block:before {
    content: '';
    display: inline-block;
    height: 90%;
    position: absolute;
    right: -1px;
    top: 5%;
    width: 1px;
}

// .features-block:hover .block-icon {
//     -webkit-transform: translateY(-5px);
//     transform: translateY(-5px);
// }

// .features-block:hover .block-icon:before {
//     opacity: 1;
//     -webkit-transform: translateY(5px);
//     transform: translateY(5px);
// }

.features-block .block-icon {
    border-radius: 50%;
    display: inline-block;
    height: 75px;
    text-align: center;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 75px;

    img {
        width: 40px;
        margin: 15px auto 0;
    }
}

.features-block .block-icon:before {
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, transparent 80%);
    content: '';
    height: 10px;
    left: 5%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
    width: 90%;
    z-index: -1;
}

.features-block .block-icon i {
    line-height: 75px !important;
    font-size: 2.18rem;
}


/*
* Flickity
*/

.carousel-custom.flickity-enabled.is-draggable .flickity-viewport,
.carousel-custom.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: url("/static/images/template/icon-left-right.png"), move !important;
    cursor: url("/static/images/template/icon-left-right.png"), -webkit-grab !important;
    cursor: url("/static/images/template/icon-left-right.png"), grab !important;
}

.carousel-custom.carousel-fullscreen,
.carousel-custom.carousel-fullscreen .flickity-viewport .flickity-slider,
.carousel-custom-control .control-wrap,
.carousel-custom-control .control-wrap .control-next,
.carousel-custom-control .control-wrap .control-previous {
    height: 100%;
}

.carousel-custom-control,
.carousel-custom-control .control-wrap,
.carousel-custom-control .control-wrap .control-next,
.carousel-custom-control .control-wrap .control-previous {
    position: absolute;
}

.carousel-custom,
.carousel-custom-wrap {
    position: relative;
}

.carousel-custom-control,
.carousel-custom-control .control-wrap .control-previous {
    left: 0;
}

.carousel-custom-control .control-wrap,
.carousel-custom-control .control-wrap .control-next {
    right: 0;
}

.carousel-custom-control .control-wrap,
.carousel-custom-control .control-wrap .control-next,
.carousel-custom-control .control-wrap .control-previous {
    top: 0;
}

.carousel-custom-control .control-wrap .control-next,
.carousel-custom-control .control-wrap .control-previous {
    width: 50%;
}

.carousel-custom.carousel-fullscreen,
.carousel-custom.carousel-fullscreen .flickity-viewport,
.carousel-custom.carousel-fullscreen .flickity-viewport .flickity-slider,
.carousel-custom-wrap,
.carousel-custom-control {
    width: 100%;
}

.carousel-custom.carousel-fullscreen .flickity-viewport {
    height: 100% !important;
}

.carousel-custom.carousel-fullscreen .flickity-page-dots {
    bottom: 40px;
}

.carousel-custom.carousel-fullscreen .flickity-page-dots .dot {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 1px;
    height: 4px;
    opacity: 1;
    width: 30px;
}

.carousel-custom .flickity-page-dots {
    bottom: -32px;
}

.carousel-custom .flickity-page-dots .dot {
    margin: 0 6px;
}

.carousel-custom-control {
    bottom: 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.carousel-custom-control .control-wrap {
    width: 100px;
}

.carousel-custom-control .control-wrap .control-next {
    color: #ffffff;
}

.carousel-custom-control .control-wrap .control-previous {
    color: #ffffff;
}


/*
* Font family
*/
/* 
.font-base {
    font-family: "Roboto", sans-serif !important;
}
.font-alt {
    font-family: $font-NotoSansHK, sans-serif !important;
} */


/*
* Font weight
*/

.font-w-300 {
    font-weight: 300 !important;
}

.font-w-400 {
    font-weight: 400 !important;
}

.font-w-600 {
    font-weight: 600 !important;
}

.font-w-700 {
    font-weight: 700 !important;
}

.font-w-900 {
    font-weight: 900 !important;
}


/*
* Form
*/

input[type="email"].form-control,
input[type="text"].form-control,
textarea.form-control,
select.form-control {
    font-size: 0.875rem !important;
    letter-spacing: 0.025rem !important;
    padding: 0.75rem 0.875rem;
}

select.form-control {
    font-size: 0.875rem !important;
    height: auto !important;
    letter-spacing: 0.025rem !important;
    padding: 0.657rem 0.625rem;
}

label.error {
    color: #ffffff;
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.form-group {
    margin-bottom: 1.25rem;
}

.form-group--error {
    border: 1px solid #cc0000;
    padding: 10px;
}

.form-group--success {
    border: 1px solid #fff;
    padding: 10px;
    text-align: center;
}

.form-control.error {
    border-color: #cc0000;
}

::-webkit-input-placeholder {
    color: #20222b !important;
    font-size: 0.875rem !important;
    letter-spacing: 0.025rem !important;
    opacity: 1 !important;
    text-overflow: ellipsis !important;
}

:-moz-placeholder {
    color: #20222b !important;
    font-size: 0.875rem !important;
    letter-spacing: 0.025rem !important;
    opacity: 1 !important;
    text-overflow: ellipsis !important;
}

:-ms-input-placeholder {
    color: #20222b !important;
    font-size: 0.875rem !important;
    letter-spacing: 0.025rem !important;
    opacity: 1 !important;
    text-overflow: ellipsis !important;
}


/*
* Gallery
*/

.gallery-grid figcaption,
.gallery-grid img {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.gallery-wrapper {
    clear: both;
    width: 100%;
}

.gallery-grid .item {
    float: left;
}

.gallery-grid figure {
    height: 100%;
    margin: 0 !important;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.gallery-grid figure:hover figcaption {
    opacity: 1;
}

.gallery-grid figcaption {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.gallery-grid figcaption a {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.gallery-grid figcaption .d-table {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
}

.gallery-grid img {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
}


/*
* Gallery - Grid & gutter
*/

.gallery-grid.grid-col-2 .item {
    width: 50%;
}

.gallery-grid.grid-col-3 .item {
    width: 33.33%;
}

.gallery-grid.grid-col-4 .item {
    width: 25%;
}

.gallery-grid.gutter-small {
    margin-left: -11px;
}

.gallery-grid.gutter-small .item {
    padding: 0 0 11px 11px;
}

.gallery-grid.gutter-medium {
    margin-left: -22px;
}

.gallery-grid.gutter-medium .item {
    padding: 0 0 22px 22px;
}

.gallery-grid.gutter-wide {
    margin-left: -40px;
}

.gallery-grid.gutter-wide .item {
    padding: 0 0 40px 40px;
}


/*
* Gallery - Hover effect
*/

.gallery-grid figure:hover img {
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
}

.gallery-grid figure a {
    cursor: url("/static/images/template/icon-zoom-in-white.png"), pointer;
}


/*
* Gallery - Magnific popup
*/

.mfp-bg {
    background: #ffffff;
}

.mfp-figure:after {
    -webkit-box-shadow: 0 50px 80px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 50px 80px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 50px 80px 0 rgba(0, 0, 0, 0.2);
}

.mfp-title,
.mfp-counter {
    font-family: $font-NotoSansHK, sans-serif;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.mfp-title,
.mfp-counter-curr {
    color: #000000;
}

.mfp-counter {
    color: #666666;
}

.mfp-zoom-out-cur {
    cursor: url("/static/images/template/icon-zoom-out-dark.png"), -moz-zoom-out;
    cursor: url("/static/images/template/icon-zoom-out-dark.png"), -webkit-zoom-out;
    cursor: url("/static/images/template/icon-zoom-out-dark.png"), zoom-out;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}

.mfp-image-holder .mfp-close {
    color: #000000;
}

.mfp-iframe-holder .mfp-close {
    color: #000000;
}

.mfp-arrow {
    height: 60px !important;
    margin-top: -30px !important;
    width: 60px !important;
}

.mfp-arrow:before,
.mfp-arrow:after {
    display: none !important;
}

.mfp-arrow-right,
.mfp-arrow-left {
    background-position: center center !important;
    background-repeat: no-repeat !important;
}

.mfp-arrow-right {
    background-image: url("/static/images/template/icon-arrow-right-dark.png") !important;
    right: 25px !important;
}

.mfp-arrow-left {
    background-image: url("/static/images/template/icon-arrow-left-dark.png") !important;
    left: 25px !important;
}


/*
* Gallery - Magnific popup (Fade effect)
*/

.mfp-fade.mfp-bg,
.mfp-fade.mfp-wrap .mfp-content {
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg,
.mfp-fade.mfp-bg.mfp-removing,
.mfp-fade.mfp-wrap .mfp-content,
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.98;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}


/*
* Gallery - Magnific popup (Popup inline)
*/

.popup-wrap .mfp-container {
    padding: 0;
}

.popup-wrap .mfp-content {
    min-height: 100%;
}

.popup-wrap button.mfp-close {
    background-color: #000000;
    color: #ffffff;
    opacity: 1;
}

.popup-wrap button.mfp-close:active {
    top: 0;
}

.popup-main {
    display: none;
}

.popup-content {
    height: 100%;
    position: relative;
    width: 100%;
}

.popup-content .container {
    padding-bottom: 80px;
    padding-top: 80px;
}


/*
* Heading style
*/

.title-underline-base-color,
.title-underline-black,
.title-underline-black-light,
.title-underline-gray,
.title-underline-gray-light,
.title-underline-gray-dark,
.title-underline-white,
.title-underline-blue,
.title-underline-blue-2,
.title-underline-green,
.title-underline-orange,
.title-underline-orange-2,
.title-underline-pink,
.title-underline-purple,
.title-underline-red,
.title-underline-thick-base-color,
.title-underline-thick-black,
.title-underline-thick-black-light,
.title-underline-thick-gray,
.title-underline-thick-gray-light,
.title-underline-thick-gray-dark,
.title-underline-thick-white,
.title-underline-thick-blue,
.title-underline-thick-blue-2,
.title-underline-thick-green,
.title-underline-thick-orange,
.title-underline-thick-orange-2,
.title-underline-thick-pink,
.title-underline-thick-purple,
.title-underline-thick-red,
.title-sideline-base-color,
.title-sideline-black,
.title-sideline-black-light,
.title-sideline-gray,
.title-sideline-gray-light,
.title-sideline-gray-dark,
.title-sideline-white,
.title-sideline-blue,
.title-sideline-blue-2,
.title-sideline-green,
.title-sideline-orange,
.title-sideline-orange-2,
.title-sideline-pink,
.title-sideline-purple,
.title-sideline-red {
    display: inline-block;
}

.title-sideline-base-color,
.title-sideline-black,
.title-sideline-black-light,
.title-sideline-gray,
.title-sideline-gray-light,
.title-sideline-gray-dark,
.title-sideline-white,
.title-sideline-blue,
.title-sideline-blue-2,
.title-sideline-green,
.title-sideline-orange,
.title-sideline-orange-2,
.title-sideline-pink,
.title-sideline-purple,
.title-sideline-red {
    position: relative;
}

.title-underline-base-color,
.title-underline-black,
.title-underline-black-light,
.title-underline-gray,
.title-underline-gray-light,
.title-underline-gray-dark,
.title-underline-white,
.title-underline-blue,
.title-underline-blue-2,
.title-underline-green,
.title-underline-orange,
.title-underline-orange-2,
.title-underline-pink,
.title-underline-purple,
.title-underline-red {
    border-bottom: 2px solid transparent;
    padding-bottom: 3px;
}

.title-underline-thick-base-color,
.title-underline-thick-black,
.title-underline-thick-black-light,
.title-underline-thick-gray,
.title-underline-thick-gray-light,
.title-underline-thick-gray-dark,
.title-underline-thick-white,
.title-underline-thick-blue,
.title-underline-thick-blue-2,
.title-underline-thick-green,
.title-underline-thick-orange,
.title-underline-thick-orange-2,
.title-underline-thick-pink,
.title-underline-thick-purple,
.title-underline-thick-red {
    border-bottom: 5px solid transparent;
    padding-bottom: 4px;
}

.title-sideline-base-color,
.title-sideline-black,
.title-sideline-black-light,
.title-sideline-gray,
.title-sideline-gray-light,
.title-sideline-gray-dark,
.title-sideline-white,
.title-sideline-blue,
.title-sideline-blue-2,
.title-sideline-green,
.title-sideline-orange,
.title-sideline-orange-2,
.title-sideline-pink,
.title-sideline-purple,
.title-sideline-red {
    padding: 0;
}

.title-sideline-base-color:before,
.title-sideline-black:before,
.title-sideline-black-light:before,
.title-sideline-gray:before,
.title-sideline-gray-light:before,
.title-sideline-gray-dark:before,
.title-sideline-white:before,
.title-sideline-blue:before,
.title-sideline-blue-2:before,
.title-sideline-green:before,
.title-sideline-orange:before,
.title-sideline-orange-2:before,
.title-sideline-pink:before,
.title-sideline-purple:before,
.title-sideline-red:before {
    content: '';
    margin-top: -2px;
    position: absolute;
    left: -20px;
    top: 50%;
    width: 12px;
    height: 4px;
}

.title-underline-black {
    border-color: #000000;
}

.title-underline-thick-black {
    border-color: #000000;
}

.title-underline-black-light {
    border-color: #20222b;
}

.title-underline-thick-black-light {
    border-color: #20222b;
}

.title-underline-gray {
    border-color: #fafafa;
}

.title-underline-thick-gray {
    border-color: #fafafa;
}

.title-underline-gray-light {
    border-color: #fcfcfc;
}

.title-underline-thick-gray-light {
    border-color: #fcfcfc;
}

.title-underline-gray-dark {
    border-color: #666666;
}

.title-underline-thick-gray-dark {
    border-color: #666666;
}

.title-underline-white {
    border-color: #ffffff;
}

.title-underline-thick-white {
    border-color: #ffffff;
}

.title-underline-blue {
    border-color: #016cff;
}

.title-underline-thick-blue {
    border-color: #016cff;
}

.title-underline-blue-2 {
    border-color: #009cdb;
}

.title-underline-thick-blue-2 {
    border-color: #009cdb;
}

.title-underline-green {
    border-color: #0baf4e;
}

.title-underline-thick-green {
    border-color: #0baf4e;
}

.title-underline-orange {
    border-color: #f9461d;
}

.title-underline-thick-orange {
    border-color: #f9461d;
}

.title-underline-orange-2 {
    border-color: #f26702;
}

.title-underline-thick-orange-2 {
    border-color: #f26702;
}

.title-underline-pink {
    border-color: #ff1a56;
}

.title-underline-thick-pink {
    border-color: #ff1a56;
}

.title-underline-purple {
    border-color: #aa2e85;
}

.title-underline-thick-purple {
    border-color: #aa2e85;
}

.title-underline-red {
    border-color: #ef4135;
}

.title-underline-thick-red {
    border-color: #ef4135;
}

.title-sideline-black:before {
    background-color: #000000;
}

.title-sideline-black-light:before {
    background-color: #20222b;
}

.title-sideline-gray:before {
    background-color: #fafafa;
}

.title-sideline-gray-light:before {
    background-color: #fcfcfc;
}

.title-sideline-gray-dark:before {
    background-color: #666666;
}

.title-sideline-white:before {
    background-color: #ffffff;
}

.title-sideline-blue:before {
    background-color: #016cff;
}

.title-sideline-blue-2:before {
    background-color: #009cdb;
}

.title-sideline-green:before {
    background-color: #0baf4e;
}

.title-sideline-orange:before {
    background-color: #f9461d;
}

.title-sideline-orange-2:before {
    background-color: #f26702;
}

.title-sideline-pink:before {
    background-color: #ff1a56;
}

.title-sideline-purple:before {
    background-color: #aa2e85;
}

.title-sideline-red:before {
    background-color: #ef4135;
}


/*
* Label
*/

.label {
    border-radius: 3px;
    display: inline-block;
    opacity: 0.98;
    padding: 14px 18px;
}

.label-extra-small {
    padding: 8px 10px 7px;
}

.label-small {
    padding: 10px 14px;
}

.label.box-shadow {
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.5);
}


/*
* Letter spacing
*/

.letter-spacing-1 {
    letter-spacing: 0.0625rem !important;
}

.letter-spacing-2 {
    letter-spacing: 0.125rem !important;
}

.letter-spacing-3 {
    letter-spacing: 0.1875rem !important;
}

.letter-spacing-4 {
    letter-spacing: 0.25rem !important;
}

.letter-spacing-5 {
    letter-spacing: 0.3125rem !important;
}

.letter-spacing-6 {
    letter-spacing: 0.375rem !important;
}

.letter-spacing-7 {
    letter-spacing: 0.4375rem !important;
}

.letter-spacing-8 {
    letter-spacing: 0.5rem !important;
}

.letter-spacing-9 {
    letter-spacing: 0.5625rem !important;
}

.letter-spacing-10 {
    letter-spacing: 0.625rem !important;
}

.no-letter-spacing {
    letter-spacing: 0rem !important;
}


/*
* Navigation
*/

.navbar,
.navbar-header,
.navbar-brand img {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.navbar {
    background-color: #000000 !important;
    opacity: 0.98;
    padding-bottom: 0;
    padding-top: 0;
}

.navbar.navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.3) !important;
}

.navbar-header {
    height: 80px;
    line-height: 80px;
}

.navbar-brand {
    padding-bottom: 0;
    padding-top: 0;
}

.navbar-brand img {
    height: 46px;
    left: auto;
    position: absolute;
    top: 17px;
}

.navbar-brand img.logo-text-black {
    opacity: 0;
}

.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 0;
    top: 20px;
}

.navbar .nav-item {
    padding: 8px 0;
}

.navbar .nav-link {
    color: #ffffff !important;
    display: inline-block;
    font-family: $font-NotoSansHK, sans-serif;
    font-size: 0.688rem;
    letter-spacing: 1px;
    padding: 0 !important;
    position: relative;
    text-transform: uppercase;
}

.navbar .nav-link.active:before {
    background-color: #ffffff !important;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    margin-bottom: -2px;
    position: absolute;
    width: 100%;
}


/*
* Opacity
*/

.opacity-0 {
    opacity: 0 !important;
}

.opacity-0-5 {
    opacity: 0.05 !important;
}

.opacity-1 {
    opacity: 0.1 !important;
}

.opacity-1-5 {
    opacity: 0.15 !important;
}

.opacity-2 {
    opacity: 0.2 !important;
}

.opacity-2-5 {
    opacity: 0.25 !important;
}

.opacity-3 {
    opacity: 0.3 !important;
}

.opacity-3-5 {
    opacity: 0.35 !important;
}

.opacity-4 {
    opacity: 0.4 !important;
}

.opacity-4-5 {
    opacity: 0.45 !important;
}

.opacity-5 {
    opacity: 0.5 !important;
}

.opacity-5-5 {
    opacity: 0.55 !important;
}

.opacity-6 {
    opacity: 0.6 !important;
}

.opacity-6-5 {
    opacity: 0.65 !important;
}

.opacity-7 {
    opacity: 0.7 !important;
}

.opacity-7-5 {
    opacity: 0.75 !important;
}

.opacity-8 {
    opacity: 0.8 !important;
}

.opacity-8-5 {
    opacity: 0.85 !important;
}

.opacity-9 {
    opacity: 0.9 !important;
}

.opacity-9-5 {
    opacity: 0.95 !important;
}

.no-opacity {
    opacity: 1 !important;
}


/*
* Overflow
*/

.overflow-hidden {
    overflow: hidden !important;
}


/*
* Pie Chart
*/

.pie-chart {
    margin: auto;
    min-height: 210px;
    min-width: 210px;
    position: relative;
    text-align: center;
}

.pie-chart .percent {
    display: block;
    height: 50px;
    left: 50%;
    line-height: 50px !important;
    margin-left: -56px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 112px;
}


/*
* Position
*/

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-relative {
    position: relative !important;
}

.top-0 {
    top: 0 !important;
}

.top-50 {
    top: 50% !important;
}

.top-100 {
    top: 100% !important;
}

.right-0 {
    right: 0 !important;
}

.right-50 {
    right: 50% !important;
}

.right-100 {
    right: 100% !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.bottom-50 {
    bottom: 50% !important;
}

.bottom-100 {
    bottom: 100% !important;
}

.left-0 {
    left: 0 !important;
}

.left-50 {
    left: 50% !important;
}

.left-100 {
    left: 100% !important;
}


/*
* Preloader
*/

.pace {
    -webkit-pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    height: 3px;
    position: fixed;
    right: 100%;
    top: 0;
    width: 100%;
    z-index: 2000;
}


/*
* Pricing
*/

.pricing .price .symbol {
    bottom: 36px;
    position: relative;
}


/*
* Scrollbar
*/

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background: rgba(210, 210, 210, 0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


/*
* Scroll to top
*/

.scroll-to-top {
    background-color: #000000;
    border-radius: 2px;
    display: none;
    position: fixed;
    right: 35px;
    top: 94%;
    text-align: center;
    z-index: 999;
}

.scroll-to-top i {
    color: #ffffff;
    font-size: 18px;
    margin: 0 6px;
}


/*
* Section
*/

section {
    padding: 60px 0px;
}


/*
* Separator
*/

.sep-line,
.sep-line-long,
.sep-line-full,
.sep-line-thin,
.sep-line-thin-long,
.sep-line-thin-full,
.sep-line-thick,
.sep-line-thick-long,
.sep-line-thick-full,
.sep-line-medium-thick,
.sep-line-medium-thick-long,
.sep-line-medium-thick-full,
.sep-line-extra-thick,
.sep-line-extra-thick-long,
.sep-line-extra-thick-full {
    display: block;
}

.sep-line-thin,
.sep-line-thin-long,
.sep-line-thin-full {
    height: 1px;
}

.sep-line,
.sep-line-long,
.sep-line-full {
    height: 2px;
}

.sep-line-thick,
.sep-line-thick-long,
.sep-line-thick-full {
    height: 4px;
}

.sep-line-medium-thick,
.sep-line-medium-thick-long,
.sep-line-medium-thick-full {
    height: 6px;
}

.sep-line-extra-thick,
.sep-line-extra-thick-long,
.sep-line-extra-thick-full {
    height: 8px;
}

.sep-line,
.sep-line-thin,
.sep-line-thick,
.sep-line-medium-thick,
.sep-line-extra-thick {
    width: 30px;
}

.sep-line-long,
.sep-line-thin-long,
.sep-line-thick-long,
.sep-line-medium-thick-long,
.sep-line-extra-thick-long {
    width: 80px;
}

.sep-line-full,
.sep-line-thin-full,
.sep-line-thick-full,
.sep-line-medium-thick-full,
.sep-line-extra-thick-full {
    width: 100%;
}


/*
* Team Block
*/

.team-block {
    overflow: hidden;
    position: relative;
}

.team-block:hover img {
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
}

.team-block:hover figcaption {
    opacity: 1;
}

.team-block figcaption {
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
}


/*
* Text color
*/

.text-black {
    color: #000000 !important;
}

.text-black-light {
    color: #20222b !important;
}

.text-gray {
    color: #fafafa !important;
}

.text-gray-light {
    color: #fcfcfc !important;
}

.text-gray-dark {
    color: #666666 !important;
}

.text-white {
    color: #ffffff !important;
}

.text-blue {
    color: #016cff !important;
}

.text-blue-2 {
    color: #009cdb !important;
}

.text-green {
    color: #0baf4e !important;
}

.text-orange {
    color: #f9461d !important;
}

.text-orange-2 {
    color: #f26702 !important;
}

.text-pink {
    color: #ff1a56 !important;
}

.text-purple {
    color: #aa2e85 !important;
}

.text-red {
    color: #ef4135 !important;
}


/*
* Text decoration
*/

.text-underline {
    text-decoration: underline !important;
}


/*
* Text size
*/

.text-extra-small {
    font-size: 0.625rem !important;
    line-height: 0.9375rem !important;
}

.text-small {
    font-size: 0.75rem !important;
    line-height: 1.125rem !important;
}

.text-medium {
    font-size: 0.875rem !important;
    line-height: 1.313rem !important;
}

.text-large {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.text-extra-large {
    font-size: 1.125rem !important;
    line-height: 1.688rem !important;
}

.title-small {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
}

.title-medium {
    font-size: 1.5rem !important;
    line-height: 1.25 !important;
}

.title-large {
    font-size: 1.75rem !important;
    line-height: 2.275rem !important;
}

.title-extra-large {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
}

.title-extra-large-2 {
    font-size: 2.75rem !important;
    line-height: 2.75rem !important;
}

.title-extra-large-3 {
    font-size: 3.125rem !important;
    line-height: 1.2;
}

.title-extra-large-4 {
    font-size: 4.375rem !important;
    line-height: 4.375rem !important;
}

.title-extra-large-5 {
    font-size: 5rem !important;
    line-height: 5rem !important;
}

.title-big {
    font-size: 6.25rem !important;
    line-height: 6.25rem !important;
}

.title-big-2 {
    font-size: 7.75rem !important;
    line-height: 7.75rem !important;
}

.title-big-3 {
    font-size: 9.375rem !important;
    line-height: 9.375rem !important;
}


/*
* Timeline
*/

.timeline {
    position: relative;
    width: 100%;
}

.timeline:before {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.timeline .timeline-item {
    margin-top: 50px;
    position: relative;
    width: 100%;
}

.timeline .timeline-item:before {
    background-color: #0baf4e;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    margin-right: -10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
}

.timeline .timeline-item:first-child {
    margin-top: 0;
}

.timeline .timeline-item .card {
    width: 92%;
}


/*
* Responsive
*/

/*
* Button size
*/

@media (max-width: 1199px) {
    .btn-lg-extra-small {
        font-size: 0.625rem;
        padding: 0.625rem 0.75rem 0.5rem;
        margin-right: 0.75rem;
    }

    .btn-lg-extra-small.btn-icon {
        height: 30px;
        line-height: 31px;
        width: 30px;
    }

    .btn-lg-small {
        font-size: 0.75rem;
        padding: 0.625rem 0.875rem;
        margin-right: 0.75rem;
    }

    .btn-lg-small.btn-icon {
        height: 35px;
        line-height: 36px;
        width: 35px;
    }

    .btn-lg-medium {
        font-size: 0.875rem;
        padding: 0.75rem 1.375rem;
    }

    .btn-lg-medium.btn-icon {
        height: 41px;
        line-height: 42px;
        width: 41px;
    }

    .btn-lg-large {
        font-size: 1rem;
        padding: 1.125rem 1.875rem;
    }

    .btn-lg-large.btn-icon {
        height: 56px;
        line-height: 57px;
        width: 56px;
    }

    .btn-lg-extra-large {
        font-size: 1.125rem;
        padding: 1.25rem 2.188rem;
    }

    .btn-lg-extra-large.btn-icon {
        height: 62px;
        line-height: 64px;
        width: 62px;
    }
}

@media (max-width: 991px) {
    .btn-md-extra-small {
        font-size: 0.625rem;
        padding: 0.625rem 0.75rem 0.5rem;
        margin-right: 0.75rem;
    }

    .btn-md-extra-small.btn-icon {
        height: 30px;
        line-height: 31px;
        width: 30px;
    }

    .btn-md-small {
        font-size: 0.75rem;
        padding: 0.625rem 0.875rem;
        margin-right: 0.75rem;
    }

    .btn-md-small.btn-icon {
        height: 35px;
        line-height: 36px;
        width: 35px;
    }

    .btn-md-medium {
        font-size: 0.875rem;
        padding: 0.75rem 1.375rem;
    }

    .btn-md-medium.btn-icon {
        height: 41px;
        line-height: 42px;
        width: 41px;
    }

    .btn-md-large {
        font-size: 1rem;
        padding: 1.125rem 1.875rem;
    }

    .btn-md-large.btn-icon {
        height: 56px;
        line-height: 57px;
        width: 56px;
    }

    .btn-md-extra-large {
        font-size: 1.125rem;
        padding: 1.25rem 2.188rem;
    }

    .btn-md-extra-large.btn-icon {
        height: 62px;
        line-height: 64px;
        width: 62px;
    }
}

@media (max-width: 767px) {
    .btn-sm-extra-small {
        font-size: 0.625rem;
        padding: 0.625rem 0.75rem 0.5rem;
        margin-right: 0.75rem;
    }

    .btn-sm-extra-small.btn-icon {
        height: 30px;
        line-height: 31px;
        width: 30px;
    }

    .btn-sm-small {
        font-size: 0.75rem;
        padding: 0.625rem 0.875rem;
        margin-right: 0.75rem;
    }

    .btn-sm-small.btn-icon {
        height: 35px;
        line-height: 36px;
        width: 35px;
    }

    .btn-sm-medium {
        font-size: 0.875rem;
        padding: 0.75rem 1.375rem;
    }

    .btn-sm-medium.btn-icon {
        height: 41px;
        line-height: 42px;
        width: 41px;
    }

    .btn-sm-large {
        font-size: 1rem;
        padding: 1.125rem 1.875rem;
    }

    .btn-sm-large.btn-icon {
        height: 56px;
        line-height: 57px;
        width: 56px;
    }

    .btn-sm-extra-large {
        font-size: 1.125rem;
        padding: 1.25rem 2.188rem;
    }

    .btn-sm-extra-large.btn-icon {
        height: 62px;
        line-height: 64px;
        width: 62px;
    }
}

@media (max-width: 575px) {
    .btn-xs-extra-small {
        font-size: 0.625rem;
        padding: 0.625rem 0.75rem 0.5rem;
        margin-right: 0.75rem;
    }

    .btn-xs-extra-small.btn-icon {
        height: 30px;
        line-height: 31px;
        width: 30px;
    }

    .btn-xs-small {
        font-size: 0.75rem;
        padding: 0.625rem 0.875rem;
        margin-right: 0.75rem;
    }

    .btn-xs-small.btn-icon {
        height: 35px;
        line-height: 36px;
        width: 35px;
    }

    .btn-xs-medium {
        font-size: 0.875rem;
        padding: 0.75rem 1.375rem;
    }

    .btn-xs-medium.btn-icon {
        height: 41px;
        line-height: 42px;
        width: 41px;
    }

    .btn-xs-large {
        font-size: 1rem;
        padding: 1.125rem 1.875rem;
    }

    .btn-xs-large.btn-icon {
        height: 56px;
        line-height: 57px;
        width: 56px;
    }

    .btn-xs-extra-large {
        font-size: 1.125rem;
        padding: 1.25rem 2.188rem;
    }

    .btn-xs-extra-large.btn-icon {
        height: 62px;
        line-height: 64px;
        width: 62px;
    }
}


/*
* Border
*/

@media (max-width: 1199px) {
    .border-lg {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-lg-top {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-lg-right {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-lg-bottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-lg-left {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-lg-thick {
        border-width: 2px !important;
    }

    .border-lg-medium-thick {
        border-width: 3px !important;
    }

    .border-lg-extra-thick {
        border-width: 4px !important;
    }

    .border-lg-top-0 {
        border-top: 0 !important;
    }

    .border-lg-right-0 {
        border-right: 0 !important;
    }

    .border-lg-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-lg-left-0 {
        border-left: 0 !important;
    }

    .border-lg-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-lg-y-0 {
        border-bottom: 0 !important;
        border-top: 0 !important;
    }

    .border-lg-0 {
        border: 0 !important;
    }
}

@media (max-width: 991px) {
    .border-md {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-md-top {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-md-right {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-md-bottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-md-left {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-md-thick {
        border-width: 2px !important;
    }

    .border-md-medium-thick {
        border-width: 3px !important;
    }

    .border-md-extra-thick {
        border-width: 4px !important;
    }

    .border-md-top-0 {
        border-top: 0 !important;
    }

    .border-md-right-0 {
        border-right: 0 !important;
    }

    .border-md-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-md-left-0 {
        border-left: 0 !important;
    }

    .border-md-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-md-y-0 {
        border-bottom: 0 !important;
        border-top: 0 !important;
    }

    .border-md-0 {
        border: 0 !important;
    }
}

@media (max-width: 767px) {
    .border-sm {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-sm-top {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-sm-right {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-sm-bottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-sm-left {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-sm-thick {
        border-width: 2px !important;
    }

    .border-sm-medium-thick {
        border-width: 3px !important;
    }

    .border-sm-extra-thick {
        border-width: 4px !important;
    }

    .border-sm-top-0 {
        border-top: 0 !important;
    }

    .border-sm-right-0 {
        border-right: 0 !important;
    }

    .border-sm-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-sm-left-0 {
        border-left: 0 !important;
    }

    .border-sm-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-sm-y-0 {
        border-bottom: 0 !important;
        border-top: 0 !important;
    }

    .border-sm-0 {
        border: 0 !important;
    }
}

@media (max-width: 575px) {
    .border-xs {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-xs-top {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-xs-right {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-xs-bottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-xs-left {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .border-xs-thick {
        border-width: 2px !important;
    }

    .border-xs-medium-thick {
        border-width: 3px !important;
    }

    .border-xs-extra-thick {
        border-width: 4px !important;
    }

    .border-xs-top-0 {
        border-top: 0 !important;
    }

    .border-xs-right-0 {
        border-right: 0 !important;
    }

    .border-xs-bottom-0 {
        border-bottom: 0 !important;
    }

    .border-xs-left-0 {
        border-left: 0 !important;
    }

    .border-xs-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .border-xs-y-0 {
        border-bottom: 0 !important;
        border-top: 0 !important;
    }

    .border-xs-0 {
        border: 0 !important;
    }
}


/*
* Countdown
*/

@media (min-width: 992px) {
    .clock .clock-item {
        min-width: 145px;
    }
}


/*
* Features Block
*/

@media (max-width: 991px) {
    .features-block:before {
        display: none;
    }
}


/*
* Flickity
*/

@media (min-width: 992px) {
    .carousel-custom-control {
        height: 64px;
        line-height: 64px;
    }

    .carousel-custom-control .control-wrap {
        width: 128px;
    }
}

@media (min-width: 1200px) {
    .carousel-custom .inner-content {
        min-height: 100%;
    }

    .carousel-custom-control .control-wrap {
        left: 50%;
    }
}


/*
* Gallery - Grid & gutter
*/

@media (max-width: 991px) {
    .gallery-grid {
        margin-left: -22px !important;
    }

    .gallery-grid .item {
        padding: 0 0 22px 22px !important;
        width: 50% !important;
    }
}

@media (max-width: 767px) {
    .gallery-grid {
        margin-left: -11px !important;
    }

    .gallery-grid .item {
        padding: 0 0 11px 11px !important;
    }
}

@media (max-width: 575px) {
    .gallery-grid {
        margin-left: -22px !important;
    }

    .gallery-grid .item {
        padding: 0 0 22px 22px !important;
        width: 100% !important;
    }
}


/*
* Navigation
*/

@media (max-width: 575px) {
    .navbar .container {
        margin: 0;
    }

    .navbar-toggler-right {
        right: 0;
    }
}

@media (min-width: 576px) and (max-width: 991px) {
    .navbar-brand {
        padding-left: 1rem;
    }

    .navbar-collapse {
        padding-left: 1rem;
    }
}

@media (max-width: 991px) {
    .navbar {
        -webkit-box-shadow: 0px 8px 17px -8px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 8px 17px -8px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 8px 17px -8px rgba(0, 0, 0, 0.1);
    }

    .navbar.navbar-light {
        background-color: #ffffff !important;
    }

    .navbar.navbar-light .navbar-brand img.logo-text-black {
        opacity: 1;
    }

    .navbar.navbar-light .navbar-brand img.logo-text-white {
        opacity: 0;
    }

    .navbar.navbar-light .navbar-collapse {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .navbar.navbar-light .nav-link {
        color: #000000 !important;
    }

    .navbar.navbar-light .nav-link.active:before {
        background-color: #000000 !important;
    }

    .navbar-collapse {
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 12px;
        padding-top: 8px;
    }
}

@media (min-width: 992px) {
    .navbar {
        background-color: rgba(0, 0, 0, 0.15) !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    .navbar.shrink {
        background-color: #000000 !important;
        -webkit-box-shadow: 0px 8px 17px -8px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 8px 17px -8px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 8px 17px -8px rgba(0, 0, 0, 0.1);
    }

    .navbar.shrink .navbar-header {
        height: 60px;
        line-height: 60px;
    }

    .navbar.shrink .navbar-brand img {
        height: 40px;
        top: 10px;
    }

    .navbar.shrink.navbar-light {
        background-color: #ffffff !important;
    }

    .navbar.shrink.navbar-light .navbar-brand img.logo-text-black {
        opacity: 1;
    }

    .navbar.shrink.navbar-light .navbar-brand img.logo-text-white {
        opacity: 0;
    }

    .navbar.shrink.navbar-light .nav-link {
        color: #000000 !important;
    }

    .navbar.shrink.navbar-light .nav-link.active:before {
        background-color: #000000 !important;
    }

    .navbar .nav-item {
        padding: 0 15px;
    }

    .navbar .nav-item:last-child {
        padding-right: 0;
    }
}


/*
* Position
*/

@media (max-width: 1199px) {
    .position-lg-absolute {
        position: absolute !important;
    }

    .position-lg-fixed {
        position: fixed !important;
    }

    .position-lg-relative {
        position: relative !important;
    }
}

@media (max-width: 991px) {
    .position-md-absolute {
        position: absolute !important;
    }

    .position-md-fixed {
        position: fixed !important;
    }

    .position-md-relative {
        position: relative !important;
    }
}

@media (max-width: 767px) {
    .position-sm-absolute {
        position: absolute !important;
    }

    .position-sm-fixed {
        position: fixed !important;
    }

    .position-sm-relative {
        position: relative !important;
    }
}

@media (max-width: 575px) {
    .position-xs-absolute {
        position: absolute !important;
    }

    .position-xs-fixed {
        position: fixed !important;
    }

    .position-xs-relative {
        position: relative !important;
    }
}


/*
* Section
*/

@media (min-width: 768px) {
    section {
        padding: 110px 0;
    }
}

@media (min-width: 992px) {
    section {
        padding: 140px 0;
    }
}


/*
* Text size
*/

@media (max-width: 1199px) {
    .text-lg-extra-small {
        font-size: 0.625rem !important;
        line-height: 0.9375rem !important;
    }

    .text-lg-small {
        font-size: 0.75rem !important;
        line-height: 1.125rem !important;
    }

    .text-lg-medium {
        font-size: 0.875rem !important;
        line-height: 1.313rem !important;
    }

    .text-lg-large {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }

    .text-lg-extra-large {
        font-size: 1.125rem !important;
        line-height: 1.688rem !important;
    }

    .title-lg-small {
        font-size: 1.25rem !important;
        line-height: 1.875rem !important;
    }

    .title-lg-medium {
        font-size: 1.5rem !important;
        line-height: 2.25rem !important;
    }

    .title-lg-large {
        font-size: 1.75rem !important;
        line-height: 2.275rem !important;
    }

    .title-lg-extra-large {
        font-size: 2.25rem !important;
        line-height: 2.925rem !important;
    }

    .title-lg-extra-large-2 {
        font-size: 2.75rem !important;
        line-height: 3.575rem !important;
    }

    .title-lg-extra-large-3 {
        font-size: 3.125rem !important;
        line-height: 4.062rem !important;
    }

    .title-lg-extra-large-4 {
        font-size: 4.375rem !important;
        line-height: 5.687rem !important;
    }

    .title-lg-extra-large-5 {
        font-size: 5rem !important;
        line-height: 6.5rem !important;
    }

    .title-lg-big {
        font-size: 6.25rem !important;
        line-height: 8.125rem !important;
    }

    .title-lg-big-2 {
        font-size: 7.75rem !important;
        line-height: 10.07rem !important;
    }

    .title-lg-big-3 {
        font-size: 9.375rem !important;
        line-height: 12.18rem !important;
    }
}

@media (max-width: 991px) {
    .text-md-extra-small {
        font-size: 0.625rem !important;
        line-height: 0.9375rem !important;
    }

    .text-md-small {
        font-size: 0.75rem !important;
        line-height: 1.125rem !important;
    }

    .text-md-medium {
        font-size: 0.875rem !important;
        line-height: 1.313rem !important;
    }

    .text-md-large {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }

    .text-md-extra-large {
        font-size: 1.125rem !important;
        line-height: 1.688rem !important;
    }

    .title-md-small {
        font-size: 1.25rem !important;
        line-height: 1.875rem !important;
    }

    .title-md-medium {
        font-size: 1.5rem !important;
        line-height: 2.25rem !important;
    }

    .title-md-large {
        font-size: 1.75rem !important;
        line-height: 2.275rem !important;
    }

    .title-md-extra-large {
        font-size: 2.25rem !important;
        line-height: 2.925rem !important;
    }

    .title-md-extra-large-2 {
        font-size: 2.75rem !important;
        line-height: 3.575rem !important;
    }

    .title-md-extra-large-3 {
        font-size: 3.125rem !important;
        line-height: 4.062rem !important;
    }

    .title-md-extra-large-4 {
        font-size: 4.375rem !important;
        line-height: 5.687rem !important;
    }

    .title-md-extra-large-5 {
        font-size: 5rem !important;
        line-height: 6.5rem !important;
    }

    .title-md-big {
        font-size: 6.25rem !important;
        line-height: 8.125rem !important;
    }

    .title-md-big-2 {
        font-size: 7.75rem !important;
        line-height: 10.07rem !important;
    }

    .title-md-big-3 {
        font-size: 9.375rem !important;
        line-height: 12.18rem !important;
    }
}

@media (max-width: 767px) {
    .text-sm-extra-small {
        font-size: 0.625rem !important;
        line-height: 0.9375rem !important;
    }

    .text-sm-small {
        font-size: 0.75rem !important;
        line-height: 1.125rem !important;
    }

    .text-sm-medium {
        font-size: 0.875rem !important;
        line-height: 1.313rem !important;
    }

    .text-sm-large {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }

    .text-sm-extra-large {
        font-size: 1.125rem !important;
        line-height: 1.688rem !important;
    }

    .title-sm-small {
        font-size: 1.25rem !important;
        line-height: 1.875rem !important;
    }

    .title-sm-medium {
        font-size: 1.5rem !important;
        line-height: 2.25rem !important;
    }

    .title-sm-large {
        font-size: 1.75rem !important;
        line-height: 2.275rem !important;
    }

    .title-sm-extra-large {
        font-size: 2.25rem !important;
        line-height: 2.925rem !important;
    }

    .title-sm-extra-large-2 {
        font-size: 2.75rem !important;
        line-height: 3.575rem !important;
    }

    .title-sm-extra-large-3 {
        font-size: 3.125rem !important;
        line-height: 4.062rem !important;
    }

    .title-sm-extra-large-4 {
        font-size: 4.375rem !important;
        line-height: 5.687rem !important;
    }

    .title-sm-extra-large-5 {
        font-size: 5rem !important;
        line-height: 6.5rem !important;
    }

    .title-sm-big {
        font-size: 6.25rem !important;
        line-height: 8.125rem !important;
    }

    .title-sm-big-2 {
        font-size: 7.75rem !important;
        line-height: 10.07rem !important;
    }

    .title-sm-big-3 {
        font-size: 9.375rem !important;
        line-height: 12.18rem !important;
    }
}

@media (max-width: 575px) {
    .text-xs-extra-small {
        font-size: 0.625rem !important;
        line-height: 0.9375rem !important;
    }

    .text-xs-small {
        font-size: 0.75rem !important;
        line-height: 1.125rem !important;
    }

    .text-xs-medium {
        font-size: 0.875rem !important;
        line-height: 1.313rem !important;
    }

    .text-xs-large {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }

    .text-xs-extra-large {
        font-size: 1.125rem !important;
        line-height: 1.688rem !important;
    }

    .title-xs-small {
        font-size: 1.25rem !important;
        line-height: 1.75rem !important;
    }

    .title-xs-medium {
        font-size: 1.5rem !important;
        line-height: 2.25rem !important;
    }

    .title-xs-large {
        font-size: 1.75rem !important;
        line-height: 2.275rem !important;
    }

    .title-xs-extra-large {
        font-size: 1.85rem !important;
        line-height: 1.35 !important;
    }

    .title-xs-extra-large-2 {
        font-size: 2.75rem !important;
        line-height: 3.575rem !important;
    }

    .title-xs-extra-large-3 {
        font-size: 3.125rem !important;
        line-height: 4.062rem !important;
    }

    .title-xs-extra-large-4 {
        font-size: 4.375rem !important;
        line-height: 5.687rem !important;
    }

    .title-xs-extra-large-5 {
        font-size: 5rem !important;
        line-height: 6.5rem !important;
    }

    .title-xs-big {
        font-size: 6.25rem !important;
        line-height: 8.125rem !important;
    }

    .title-xs-big-2 {
        font-size: 7.75rem !important;
        line-height: 10.07rem !important;
    }

    .title-xs-big-3 {
        font-size: 9.375rem !important;
        line-height: 12.18rem !important;
    }
}


/*
* Timeline
*/

@media (min-width: 992px) {
    .timeline:before {
        width: 50%;
    }

    .timeline .timeline-item {
        margin-top: -50px;
    }

    .timeline .timeline-item:before {
        left: 50%;
        margin-left: -10px;
        margin-right: 0;
        right: auto;
    }

    .timeline .timeline-item:first-child {
        margin-top: 0;
    }

    .timeline .timeline-item .card {
        width: 45%;
    }

    .timeline .timeline-item.timeline-inverted .card {
        left: 55%;
    }
}


/*
* Width and height
*/

@media (max-width: 1199px) {
    .w-lg-15 {
        width: 15% !important;
    }

    .w-lg-25 {
        width: 25% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-85 {
        width: 85% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .h-lg-15 {
        height: 15% !important;
    }

    .h-lg-25 {
        height: 25% !important;
    }

    .h-lg-50 {
        height: 50% !important;
    }

    .h-lg-75 {
        height: 75% !important;
    }

    .h-lg-85 {
        height: 85% !important;
    }

    .h-lg-100 {
        height: 100% !important;
    }
}

@media (max-width: 991px) {
    .w-md-15 {
        width: 15% !important;
    }

    .w-md-25 {
        width: 25% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-85 {
        width: 85% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .h-md-15 {
        height: 15% !important;
    }

    .h-md-25 {
        height: 25% !important;
    }

    .h-md-50 {
        height: 50% !important;
    }

    .h-md-75 {
        height: 75% !important;
    }

    .h-md-85 {
        height: 85% !important;
    }

    .h-md-100 {
        height: 100% !important;
    }
}

@media (max-width: 767px) {
    .w-sm-15 {
        width: 15% !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-85 {
        width: 85% !important;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .h-sm-15 {
        height: 15% !important;
    }

    .h-sm-25 {
        height: 25% !important;
    }

    .h-sm-50 {
        height: 50% !important;
    }

    .h-sm-75 {
        height: 75% !important;
    }

    .h-sm-85 {
        height: 85% !important;
    }

    .h-sm-100 {
        height: 100% !important;
    }
}

@media (max-width: 575px) {
    .w-xs-15 {
        width: 15% !important;
    }

    .w-xs-25 {
        width: 25% !important;
    }

    .w-xs-50 {
        width: 50% !important;
    }

    .w-xs-75 {
        width: 75% !important;
    }

    .w-xs-85 {
        width: 85% !important;
    }

    .w-xs-100 {
        width: 100% !important;
    }

    .h-xs-15 {
        height: 15% !important;
    }

    .h-xs-25 {
        height: 25% !important;
    }

    .h-xs-50 {
        height: 50% !important;
    }

    .h-xs-75 {
        height: 75% !important;
    }

    .h-xs-85 {
        height: 85% !important;
    }

    .h-xs-100 {
        height: 100% !important;
    }
}