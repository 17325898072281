/*
* Style
*/


/*
* Google font
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Roboto");
*/



/*
* Section - Home
*/

/** BG Image */
#home {
    background-image: url("/static/images/home/background-1.jpg");
}

/** BG Parallax */
#home-bg-parallax>.bg-parallax {
    background: url("/static/images/bg-parallax.jpg");
}

/** BG Slideshow */
.intro-slider .slides>.bg-cover:nth-child(1) {
    background: url("/static/images/home/background-1.jpg");
}

.intro-slider .slides>.bg-cover:nth-child(2) {
    background: url("/static/images/home/background-2.jpg");
}

.intro-slider .slides>.bg-cover:nth-child(3) {
    background: url("/static/images/home/background-3.jpg");
}

.intro-slider>.position-absolute {
    z-index: 20;
}

/** BG Video */
#home-bg-video .player:before {
    z-index: 15;
}

#home-bg-video .player.is-mobile {
    display: none;
}

#home-bg-video .container {
    position: relative;
    z-index: 20;
}


/*
* Section - Fun Facts
*/

#fun-facts {
    background-image: url("/static/images/bg-fun-facts.jpg");
}


/*
* Section - Skills
*/

#skills {
    background-image: url("/static/images/bg-skills.jpg");
}


/*
* Section - Quote
*/

#quote {
    background-image: url("/static/images/bg-quote.jpg");
}


/*
* Section - Keep In Touch
*/

#keep-in-touch {
    background-image: url("/static/images/bg-keep-in-touch.jpg");
}


/*
* Section - Coming Soon
*/

#coming-soon {
    background-image: url("/static/images/bg-coming-soon.jpg");
}


/*
* Section - Contact
*/

#contact {
    background-image: url("/static/images/home/background-4.jpg");
}


/*
* Footer
*/

.footer-logo {
    max-height: 40px;
}

.footer-icon-social {
    bottom: 10px;
    position: relative;
}