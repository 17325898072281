@import 'libs/helpers';
@import 'libs/fonts';
@import 'config';


*,
*::before,
*::after {
  @include nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.font-base {
  font-family: "Roboto", sans-serif !important;
}

.font-alt {
  font-family: $font-Ubuntu, sans-serif !important;
}


.strong {
  font-weight: $fw-bold;
}

.headline1 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: $fw-bold;
}

.nobr {
  white-space: nowrap;

  @media screen and (max-width: 490px) {
    white-space: normal;
  }
}

a {
  color: $color-orange;

  &:hover {
    color: darken($color-orange, 10);
  }
}


@mixin by-tags {
  h1 {
    @extend .headline1;
  }

  // h2 {
  //     @extend .headline2;
  // }

  // h3 {
  //     @extend .headline3;
  // }

  // p {
  //     @extend .text;
  // }

  strong {
    @extend .strong;
  }

  // a:not(.btn) {
  //     @extend .text-link;
  // }
}


// djangocms_text_ckeditor plugin wrapper class
.text-plugin-content,
.cke_editable {
  @include by-tags;
}

.cke_editable {
  padding: 10px;
}