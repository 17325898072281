.navbar {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.navbar .nav-link {
    font-size: 0.85rem !important;

    &:hover:before {
        background-color: rgba(255, 255, 255, 0.5) !important;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin-bottom: -2px;
        position: absolute;
        width: 100%;
    }
}

.navbar-toggler {
    border: none;

    &:focus {
        outline: none;
    }
}