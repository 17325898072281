/*
* Color
*/


/*
* Background color
*/

.bg-base-color {
    background-color: $color-orange !important;
}


/*
* Border color
*/

.border-base-color {
    border-color: $color-orange !important;
}


/*
* Button color
*/

.btn-base-color {
    background-color: $color-orange !important;
}


/*
* Button color - hover
*/

.btn-hover-base-color:hover {
    background-color: $color-orange !important;
}


/*
* Features Block
*/

.features-block:before {
    background: linear-gradient(to bottom, rgba($color-orange, 0) 0%, rgba($color-orange, 0.9) 15%, rgba($color-orange, 0.9) 85%, rgba($color-orange, 0) 100%);
}

// .features-block:hover .block-icon {
//     background-color: $color-orange;
// }

// .features-block:hover .block-icon i {
//     color: #ffffff;
// }

.features-block .block-icon {
    border: 1px solid $color-orange;
}

.features-block .block-icon i {
    color: $color-orange;
}


/*
* Flickity
*/

.carousel-custom .flickity-page-dots .dot.is-selected {
    background: $color-orange;
}


/*
* Heading style
*/

.title-underline-base-color {
    border-color: $color-orange;
}

.title-underline-thick-base-color {
    border-color: $color-orange;
}

.title-sideline-base-color:before {
    background-color: $color-orange;
}


/*
* Preloader
*/

.pace .pace-progress {
    background-color: $color-orange;
}


/*
* Text color
*/

.text-base-color {
    color: $color-orange !important;
}


/*
* Text color (hover)
*/

.text-hover-base-color:hover {
    color: $color-orange !important;
}


/*
* Timeline
*/

.timeline .timeline-item:before {
    background-color: $color-orange !important;
}