.testimonial-item {
    margin-bottom: 3.5em;

    &:last-child {
        margin-bottom: 0;
    }
}

.testimonial-item--reverse {
    &>.row {
        flex-direction: row-reverse;
        text-align: right !important;
    }
}

.testimonial-item__text {
    padding: 30px;
    background: #f5f5f5;
    border-radius: 7px;

    @media screen and (max-width: 575px) {
        padding-top: 50px;
    }
}

.testimonial-item__quote {
    &:before {
        content: "«";
    }
    &:after {
        content: "»";
    }
}

.testimonial-item__img {
    border-radius: 100%;
}

.testimonial-item__img-container {
    @media screen and (max-width: 575px) {
        margin-bottom: -4rem;
        z-index: 9;
    }
}