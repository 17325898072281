@import 'config';

@media screen and (max-width: 575px) {
    .row {
        padding: 0 15px;
    }

    .usp-item {}

    .usp-item__text {
        margin-left: -4rem;
    }
}

.usp-item__title {
    min-height: 60px;
    font-weight: 500;
}

.usp-item__icon-container {
    position: absolute;
    display: flex;
    left: 0;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    background-color: $color-orange;
    width: 48px;
    height: 48px;

    .usp-item__icon {
        width: 24px;
    }
}


section {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.fade-out {
    transform: translateY(50px);
    opacity: 0;
}

.usp-item__list {
    list-style: disc;
    padding-left: 1em;

    @media screen and (max-width: 575px) {
        margin-left: -4rem;
    }
}

.usp-item__list__item {
    margin-bottom: 0.5em;

    &:last-child {
        margin-bottom: 0;
    }
}

.cc-window {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.main-footer {
    .cookie-banner-open & {
        padding-bottom: 7rem !important;
    }
}